
console.log("version 11/14/24 senses");

let _BGV_SETTINGS = window.BGV_SETTINGS = window.BGV_SETTINGS || {}

// PROD
_BGV_SETTINGS.ENV = 'PROD'
_BGV_SETTINGS.PARTNER_ID = '018ee626-a796-cb39-b053-0b78561823d6'
_BGV_SETTINGS.APP_ID = '018ee62a-8948-1241-5602-42d559c47b01'
_BGV_SETTINGS.PIXEL_ID = '1180719439584207'

// ------------------ EDIT for each new flow ----------------------
_BGV_SETTINGS.PAGE_SET_ID = '0193b51a-26fb-b418-a3bd-a91fa14b12d8'
// ------------------ EDIT for each new flow ----------------------


_BGV_SETTINGS.renewDisclaimerConf = {
    afterSelectPlanBlock: false, afterSubmitButton: true,
};

_BGV_SETTINGS.timerConfig = {
    enable: true, buttonText: "Essayez maintenant", countDownSeconds: 60 * 5, // 5 minutes,
    timerDescription: "La réduction expire:",
}


_BGV_SETTINGS.lottieConfiguration = {
    'loading': {
        'Chart': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-12/Comp_3_1.json', 
            'loop': false,}
    }, 
}

_BGV_SETTINGS.pageToPopupMap = {
    'loading': ['pop-up-1', 'pop-up-2', 'pop-up-3'], 'page-name-2': ['pop-up-3'],
}


_BGV_SETTINGS.popupConfig = {
    'pop-up-1': {
        name: '1st_popup',
        title: "Saviez-vous que vous pouvez faire des exercices de Kegel n'importe où?",
        description: "",
        firstOptionValue: "Oui",
        secondOptionValue: "Non",
        delay: 1500,
        type: "default"
    }, 'pop-up-2': {
        name: '1st_popup',
        title: "Êtes-vous prêt pour une vie sexuelle améliorée?",
        description: "",
        firstOptionValue: "Oui",
        secondOptionValue: "Non",
        delay: 2000,
        type: "default"
    }, 'pop-up-3': {
        name: '1st_popup',
        title: "Saviez-vous que les exercices de Kegel sont complètement sûrs, contrairement à certains médicaments?",
        description: "",
        firstOptionValue: "Oui",
        secondOptionValue: "Non",
        delay: 1700,
        type: "default"
    },
};


_BGV_SETTINGS.LANDING_PAGE_LANGUAGE = 'fr'